import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import io from 'socket.io-client';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/_service/data-service.service';
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    customer: any;
    token: string = '';
    // private socket = io(environment.socketUrl, { transports: ['websocket']});
    private socket;
    isModalSocket: boolean = false;

    constructor(private dataService: DataService) {
        this.customer = this.dataService.getCookies('customer') || {};
        if (_.size(this.customer)) {
            // this.token = JSON.parse(this.customer).token;
            this.token = JSON.parse(this.customer).token;
        }
        this.socket = io(environment.socketUrl, {
            reconnection: true,
            reconnectionDelay: 500,
            reconnectionAttempts: 10,
            query: {
                token: this.token || '',
            },
        });
        // console.log('check 1', this.socket.connected);
        // this.socket.on('connect', function() {
        //   console.log('check 2', this.socket.connected);
        // });
    }

    private toJSON(data): any {
        return _.isString(data) ? JSON.parse(data) : data;
    }

    socketDisconnectionStatusBar() {
        var x = document.getElementById('snackbar');
        x.className = 'show';
        setTimeout(function () {
            x.className = x.className.replace('show', '');
        }, 3000);
    }
    socketReconnectionStatusBar() {
        var x = document.getElementById('snackbar_reconnection');
        x.className = 'show';
        setTimeout(function () {
            x.className = x.className.replace('show', '');
        }, 3000);
    }
    useSocketReconnection() {
        this.socketDisconnectionStatusBar();
        setTimeout(() => {
            this.socket.connect();
            this.socketReconnectionStatusBar();
        }, 5000);
    }
    joinRoom(data) {
        this.socket.emit('meeting_join', data);
    }

    reconnect() {
        console.log(this.isModalSocket);
        if (!this.isModalSocket) this.socketDisconnectionStatusBar();
        setTimeout(() => {
            this.socket.connect();
            if (!this.isModalSocket) this.socketReconnectionStatusBar();
        }, 5000);
    }

    leftRoom(data) {
        this.socket.emit('meeting_leave', data);
    }

    reconnet() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('reconnect', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                //  data =this.dataService.aesDecrypt(data)
                // console.log(data)
                // data.is_customer_emit= true;
                // this.socket.emit('open_component', this.dataService.aesWithoutEncrypt(data))
            });
            return () => {
                this.useSocketReconnection();
            };
        });
        return observable;
    }

    content_sharing() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('content_sharing', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                //  data =this.dataService.aesDecrypt(data)
                // console.log(data)
                // data.is_customer_emit= true;
                // this.socket.emit('open_component', this.dataService.aesWithoutEncrypt(data))
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    car_data() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('car_data', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'car_data';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    open_component() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('open_component', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log('Open Component Triggered ###', data);
                data.is_customer_emit = true;
                data.event_name = 'open_component';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    emiCalculated() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('emi_calculated', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                // console.log(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'emi_calculated';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    priceCalculated() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('price_calculated', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'price_calculated';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    changeImage() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('change_image', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log('Change Image Triggered ###', data);
                data.is_customer_emit = true;
                data.event_name = 'change_image';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    changeVideo() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('change_video', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'change_video';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    add_model_comparison() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('add_model_comparison', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'add_model_comparison';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    remove_model_comparison() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('remove_model_comparison', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'remove_model_comparison';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    collapse_model_comparison() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('collapse_model_comparison', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'collapse_model_comparison';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    add_variant_comparison() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('add_variant_comparison', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'add_variant_comparison';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    remove_variant_comparison() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('remove_variant_comparison', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'remove_variant_comparison';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    accessories_event() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('accessories_event', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'accessories_event';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    insurance_event() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('insurance_event', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'insurance_event';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    extended_warranty_event() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('extended_warranty_event', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'extended_warranty_event';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    reward_event() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('reward_event', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'reward_event';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    check_warranty_event() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('check_warranty_event', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'check_warranty_event';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    modal_event_open() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('modal_event_open', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'modal_event_open';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = true;
                this.reconnect();
            };
        });

        return observable;
    }

    modal_event_close() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('modal_event_close', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'modal_event_close';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    extended_warranty() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('extended_warranty', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'extended_warranty';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    check_uncheck_insurance_event() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('check_uncheck_insurance_event', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'check_uncheck_insurance_event';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });

        return observable;
    }

    preview_prebooking() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('preview_prebooking', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'preview_prebooking';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }
    save_prebooking() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('save_prebooking', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'save_prebooking';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    is_customer_verify(data) {
        this.socket.emit('is_customer_verify', data);
    }

    is_customer_verified() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('is_customer_verify', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'is_customer_verify';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    verified_prebooking() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('verify_prebooking', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'verify_prebooking';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    resend_otp_prebooking() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('resend_otp_prebooking', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'resend_otp_prebooking';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    push_variant_comparison() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('push_variant_comparison', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'push_variant_comparison';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    pop_variant_comparison() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('pop_variant_comparison', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'pop_variant_comparison';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    collapse_variant_comparison() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('collapse_variant_comparison', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.da taService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'collapse_variant_comparison';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    collapse_feature_specification() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('collapse_feature_specification', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'collapse_feature_specification';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    hide_common_features() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('hide_common_features', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'hide_common_features';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    videoPlayPause(data) {
        this.socket.emit('video_play_pause', data);
    }

    refreshComponent(data) {
        this.socket.emit('refresh_component_event', data);
    }

    add_variant_buildup() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('add_variant_buildup', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'add_variant_buildup';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    remove_variant_buildup() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('remove_variant_buildup', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'remove_variant_buildup';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    collapse_variant_buildup() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('collapse_variant_buildup', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'collapse_variant_buildup';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                console.log('error');
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }

    refresh_component_event(data) {
        this.socket.emit('refresh_component_event', data);
    }

    video_full_screen() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('video_full_screen', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data.is_customer_emit = true;
                data.event_name = 'video_full_screen';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }
    dse_meeting_joined() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('dse_meeting_joined', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                //  data.is_customer_emit= true;
                // data.event_name= 'dse_meeting_joined';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }
    activate_open_link() {
        let observable = new Observable<any>((observer) => {
            this.socket.on('activate_open_link', (data) => {
                data = this.toJSON(data);
                observer.next(data);
                // data = this.dataService.aesDecrypt(data);
                console.log(data);
                data = { is_customer_emit: true };
                data.event_name = 'activate_open_link';
                this.socket.emit(
                    'customer_socket_recieved',
                    this.dataService.aesWithoutEncrypt(data)
                );
            });
            return () => {
                this.isModalSocket = false;
                this.reconnect();
            };
        });
        return observable;
    }
}
