import { SocketService } from './meeting/_service/socket.service';
import { DataService } from 'src/app/_service/data-service.service';
import { Component, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    ActivatedRoute,
} from '@angular/router';
import { SpeedTestService } from 'ng-speed-test';
export let browserRefresh = false;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'Sales-Interaction-Customer-Web';
    subscription: Subscription;
    subscribe: Subscription;
    meeting_id: string = '';
    status: string;
    @HostListener('window:resize', ['$event'])
    showMessage($event) {
        $event.returnValue = 'Your data will be lost!';
    }

    constructor(
        private router: Router,
        private actRoute: ActivatedRoute,
        private socketService: SocketService,
        private dataService: DataService,
        private speedTest: SpeedTestService
    ) {
        let pathname = window.location.pathname;
        this.meeting_id = this.actRoute.snapshot.paramMap.get('meeting_id');
        this.speedTest.getKbps().subscribe((speed) => {
            if (speed < 1024)
                this.snackbar(
                    'internet_connection',
                    'Slow internet connection'
                );
        });
        this.speedTest.isOnline().subscribe((status) => {
            if (!status)
                this.snackbar('internet_connection', 'No internet connection');
        });
        this.subscription = router.events.subscribe(async (event) => {
            if (event instanceof NavigationStart) {
            }
        });

        window.onbeforeunload = (event) => {
            const e = event || window.event;
            // Cancel the event
            if (e) {
                if (
                    this.router.url.includes('meetings') ||
                    this.router.url.includes('customer')
                ) {
                    e.preventDefault();
                    e.returnValue = ''; // Legacy method for cross browser support
                }
            }
            // return ''; // Legacy method for cross browser support
        };

        // window.addEventListener('beforeunload', function (e) {
        //   console.log('sds')
        //   // the absence of a returnValue property on the event will guarantee the browser unload happens
        //   delete e['returnValue'];
        // });
        //   router.events.pipe(
        //     filter(
        //       ( event: NavigationStart ) => {
        //         return( event instanceof NavigationStart );
        //       })).subscribe(
        //     ( event: NavigationStart ) => {
        //       if(event.url.includes('meetingId')) {
        //         if (window.history && window.history.pushState) {
        //           $(window).on('popstate', function() {
        //             const confirmBtn = confirm('Do you want to leave ongoing meeting!');
        //             console.log(confirmBtn ,'d')
        //             if(confirmBtn== true) {
        //               history.go(0)
        //             } else if(confirmBtn == false){
        //               history.go(1)
        //             }
        //             // confirmBtn == true ? history.go(0): console.log(window.history)
        //           });
        //         }
        //       }
        //     }
        //   )
        // ;
    }

    snackbar(id, text) {
        let x = document.getElementById(id);
        this.status = text;
        x.className = 'show';
        setTimeout(function () {
            x.className = x.className.replace('show', '');
        }, 3000);
    }

    ngOnInit() {}
}
