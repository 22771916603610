// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    baseUrl: 'https://api.marutisuzukicustomerinteraction.com/api/', // qa sub domain
    socketUrl: 'https://ws.marutisuzukicustomerinteraction.com', // qa socket
    webexBaseUrl:
        'https://webex-html-app-sandbox.s3.ap-south-1.amazonaws.com/maruti-dsit/index.html',
    webexResizeUrl:
        'https://webex-html-app-sandbox.s3.ap-south-1.amazonaws.com/maruti-dsit/wrapper.html',
    env: 'prod',
    production: true,
    feedback_token: 'MR-277495',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
