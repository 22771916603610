import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-emi-calculator',
    templateUrl: './emi-calculator.component.html',
    styleUrls: ['./emi-calculator.component.scss'],
})
export class EmiCalculatorComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
