import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxStarRatingModule } from 'ngx-star-rating';
import bootstrap from 'bootstrap';

// import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';

import { DataService } from 'src/app/_service/data-service.service';
import { BasicAuthInterceptor, ErrorInterceptor } from './_helper/index';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatInboxComponent } from './chat-inbox/chat-inbox.component';
import { VideoCallComponent } from './video-call/video-call.component';
import { SelectCarComponent } from './select-car/select-car.component';
import { FeaturesVideoComponent } from './features-video/features-video.component';
import { EmiCalculatorComponent } from './emi-calculator/emi-calculator.component';
import { CarBrochureComponent } from './car-brochure/car-brochure.component';
import { CarImagesComponent } from './car-images/car-images.component';
import { CarCompareComponent } from './car-compare/car-compare.component';
import { CustomerWelcomeComponent } from './customer-welcome/customer-welcome.component';
import { CustomerPreferencesComponent } from './customer-preferences/customer-preferences.component';
import { RmProfileComponent } from './rm-profile/rm-profile.component';

import {
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    MeetingCompletedComponent,
} from './index';
import { ChatService } from './_service/chat.service';
import { CarInfoComponent } from './car-info/car-info.component';
import { MarutiSubscribeComponent } from './maruti-subscribe/maruti-subscribe.component';
import { SpeedTestModule, SpeedTestService } from 'ng-speed-test';

@NgModule({
    declarations: [
        AppComponent,
        ChatInboxComponent,
        HeaderComponent,
        VideoCallComponent,
        SelectCarComponent,
        FeaturesVideoComponent,
        EmiCalculatorComponent,
        CarBrochureComponent,
        CarImagesComponent,
        CarCompareComponent,
        CustomerWelcomeComponent,
        CustomerPreferencesComponent,
        RmProfileComponent,
        NotFoundComponent,
        HomeComponent,
        CarInfoComponent,
        MarutiSubscribeComponent,
        MeetingCompletedComponent,
    ],
    imports: [
        BrowserModule,
        NgbModule,
        // NgScrollbarModule,
        NgxStarRatingModule,
        CarouselModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastrModule.forRoot({
            timeOut: 3000,
        }),
        FormsModule,
        ReactiveFormsModule,
        NgxUiLoaderModule,
        SpeedTestModule,
    ],
    providers: [
        DataService,
        ChatService,
        NgxUiLoaderService,
        SpeedTestService,
        NgbModal,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
