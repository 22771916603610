import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-features-video',
    templateUrl: './features-video.component.html',
    styleUrls: ['./features-video.component.scss'],
})
export class FeaturesVideoComponent implements OnInit {
    @ViewChild('owlMac', { static: false }) owlMac: CarouselComponent;
    @ViewChild('owlCat', { static: false }) owlCat: CarouselComponent;

    categoriesOptions: any = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        center: false,
        margin: 10,
        dotsSpeed: 300,
        items: 3,
        responsive: {
            0: {
                items: 3,
                loop: true,
            },
            400: {
                items: 3,
                loop: true,
            },
            740: {
                items: 3,
                loop: true,
            },
            940: {
                items: 3,
                loop: true,
            },
            1300: {
                items: 3,
                loop: true,
            },
        },
    };

    carouselOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        // dots: true,
        dragEndSpeed: 450,
        items: 1,
    };

    categories: any = {
        items: [
            {
                id: 'slide-1',
                displayedName: 'Dual tone',
                image: 'assets/images/vid-thumb-1.png',
            },
            {
                id: 'slide-2',
                displayedName: 'Sporty Cross Mesh',
                image: 'assets/images/vid-thumb-1.png',
            },
            {
                id: 'slide-3',
                displayedName: 'LED Projector Headlamp',
                image: 'assets/images/vid-thumb-1.png',
            },
        ],
    };
    categorieVid: any = {
        items: [
            {
                id: 'slide-1',
                displayedName: 'Slide 1',
                image: 'assets/images/video_img.png',
            },
            {
                id: 'slide-2',
                displayedName: 'Slide 2',
                image: 'assets/images/video_img.png',
            },
            {
                id: 'slide-3',
                displayedName: 'Slide 3',
                image: 'assets/images/video_img.png',
            },
        ],
    };
    constructor() {}

    slideTo(category: string) {
        this.owlMac.moveByDot(category);
    }

    changeSlide($event) {
        if (this.owlCat) {
            // this.category$.next($event.slides[0].id);
            this.owlCat.moveByDot(
                this.owlCat.dotsData.dots[$event.startPosition].id
            );
        }
    }

    ngOnInit() {}
}
