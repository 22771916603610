import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-car-brochure',
    templateUrl: './car-brochure.component.html',
    styleUrls: ['./car-brochure.component.scss'],
})
export class CarBrochureComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
