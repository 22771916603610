import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MeetingGuard } from './_helper/index';
import {
    NotFoundComponent,
    HomeComponent,
    MeetingCompletedComponent,
} from './index';
import { ChatInboxComponent } from './chat-inbox/chat-inbox.component';
// import { CustomerFeedbackComponent } from './core/customer-feedback/customer-feedback.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                component: HomeComponent,
                path: 'customer',
                loadChildren: () =>
                    import('./customer/customer.module').then(
                        (m) => m.CustomerModule
                    ),
            },
            {
                path: 'meetings',
                component: HomeComponent,
                loadChildren: () =>
                    import('./meeting/meeting.module').then(
                        (m) => m.MeetingModule
                    ),
                canActivate: [MeetingGuard],
            },
            {
                path: 'meeting-completed',
                component: MeetingCompletedComponent,
            },
            // {
            //   path:'customer-feedback',
            //   component:CustomerFeedbackComponent
            // },
            {
                path: '**',
                component: NotFoundComponent,
            },
        ],
    },
    {
        path: 'chat-inbox',
        component: ChatInboxComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
