import { Injectable, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    httpOptions: any;
    private subject = new Subject<any>();

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private loader: NgxUiLoaderService
    ) {}

    post(url, data) {
        return this.http.post(environment.baseUrl + url, data);
    }

    get(url) {
        return this.http.get(environment.baseUrl + url);
    }

    public setCookies(name, value, days?: any): any {
        let date, expires;
        if (days) {
            date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toGMTString();
        } else {
            expires = '';
        }
        return (document.cookie = name + '=' + value + expires + '; path=/');
    }

    public getCookies(cname): any {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    public removeCookies(name, value): any {
        let date, expires;
        date = new Date();
        date.setTime(date.getTime());
        expires = '; expires=' + date.toGMTString();

        document.cookie = name + '=' + value + expires + '; path=/';
    }

    public deleteAllCookies() {
        let date, expires;
        date = new Date();
        date.setTime(date.getTime());
        expires = '; expires=' + date.toGMTString();
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=' + '' + expires + '; path=/';
        }
    }

    showSuccess(message) {
        this.toastr.success(message);
    }

    showError(message) {
        this.toastr.error(message);
    }
    showInfo(message) {
        this.toastr.info(message);
    }
    startLoader() {
        this.loader.start();
    }

    stopLoader() {
        this.loader.stop();
    }

    public logout() {
        // this.stopLoader();
        this.removeCookies('currentUsertoken', '');
        this.removeCookies('currentUser', '');
        window.location.href = 'auth/login';
    }

    sendData(data: object) {
        this.subject.next(data);
    }

    clearData() {
        this.subject.next();
    }

    getData(): Observable<any> {
        return this.subject.asObservable();
    }

    aesEncrypt(data) {
        let plainText = '';

        if (typeof data == 'string') plainText = data;
        else plainText = JSON.stringify(data);

        var salt = 'S#j9}rF1gMv-?c-g';
        var iv = new Buffer(16);
        iv.fill(0);

        try {
            let _iv = CryptoJS.enc.Utf8.parse(iv);
            let _key = CryptoJS.enc.Utf8.parse(salt);
            let encrypted = CryptoJS.AES.encrypt(plainText, _key, {
                iv: _iv,
            }).toString();
            return encrypted;
        } catch (error) {
            return false;
        }
    }

    aesWithoutEncrypt(data) {
        return data;
    }

    aesDecrypt(encText) {
        var salt = 'S#j9}rF1gMv-?c-g';
        var iv = new Buffer(16);
        iv.fill(0);
        try {
            let _iv = CryptoJS.enc.Utf8.parse(iv);
            let _key = CryptoJS.enc.Utf8.parse(salt);
            let decrypted = CryptoJS.AES.decrypt(encText, _key, {
                iv: _iv,
            });
            decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
            return decrypted;
        } catch (e) {
            return false;
        }
    }

    numericOnly(value) {
        if (value >= 48 && value <= 57) return true;
        return false;
    }
}
