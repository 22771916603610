import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    CanDeactivate,
} from '@angular/router';
import { DataService } from 'src/app/_service/data-service.service';
import { WebexInterfaceComponent } from 'src/app/meeting';
import { Observable } from 'rxjs';
export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable({ providedIn: 'root' })
export class MeetingGuard
    implements CanActivate, CanDeactivate<CanComponentDeactivate>
{
    constructor(
        private router: Router,
        private dataService: DataService
    ) {}
    // canDeactivate(component: WebexInterfaceComponent) {
    //     return component.canDeactivate ? component.canDeactivate() : true;
    //   }
    canDeactivate(
        component: CanComponentDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return component.canDeactivate();
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let url: any = window.location.pathname;
        url = url.replace('meetings', 'customer');
        let customer = this.dataService.getCookies('customer') || '';
        var isMeetingJoin = this.dataService.getCookies('isMeetingJoin') || '';
        var agree = this.dataService.getCookies('agree') || '';
        var training = this.dataService.getCookies('training') || '';
        if (isMeetingJoin && _.size(customer) && agree && training) {
            return true;
        }
        // not logged in so redirect to login page
        // this.router.navigate([`customer/${this.meeting_id}/${this.customer_id}`]);
        // this.router.navigate([url]);
        this.router.navigate(['/not-found']).then(() => {
            window.location.reload();
        });
        return false;
    }
}
