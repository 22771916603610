import { Component, OnInit, ViewChild } from '@angular/core';
import {
    CarouselComponent,
    OwlOptions,
    SlidesOutputData,
} from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';

@Component({
    selector: 'app-video-call',
    templateUrl: './video-call.component.html',
    styleUrls: ['./video-call.component.scss'],
})
export class VideoCallComponent implements OnInit {
    source_url = environment.webexBaseUrl;
    // source_url = environment.webexResizeUrl;
    meeting_id = '94c9fc8593f0421388afb2c1bbb3c92f';
    email = 'surendra.borana@gslab.com';
    name = 'Surendra Borana';
    showMeeting: boolean = false;
    meetingStartIn = 10000;
    closeResult: string;

    carouselOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: true,
        pullDrag: true,
        dots: true,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            740: {
                items: 1,
            },
            940: {
                items: 1,
            },
        },
        nav: true,
    };

    categorieVid: any = {
        items: [
            {
                id: 'slide-1',
                displayedName: 'Slide 1',
                image: 'assets/images/meeting-not-started.svg',
            },
            {
                id: 'slide-2',
                displayedName: 'Slide 2',
                image: 'assets/images/meeting-not-started.svg',
            },
            {
                id: 'slide-3',
                displayedName: 'Slide 3',
                image: 'assets/images/meeting-not-started.svg',
            },
        ],
    };

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder
    ) {}

    open(content) {
        this.modalService
            .open(content, { ariaLabelledBy: 'modal-basic-title' })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {
        this.source_url += `?meetingId=${this.meeting_id}&email=${this.email}&name=${this.name}`;
        // document.getElementById('iframe').setAttribute("src", this.source_url);

        setTimeout(() => {
            this.showMeeting = true;
        }, this.meetingStartIn);
    }

    termsform = new FormGroup({
        check1: new FormControl('', [Validators.required]),
        check2: new FormControl('', [Validators.required]),
    });

    get check1() {
        return this.termsform.get('check1');
    }

    get check2() {
        return this.termsform.get('check2');
    }
}
