import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-maruti-subscribe',
    templateUrl: './maruti-subscribe.component.html',
    styleUrls: ['./maruti-subscribe.component.scss'],
})
export class MarutiSubscribeComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
