import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-customer-welcome',
    templateUrl: './customer-welcome.component.html',
    styleUrls: ['./customer-welcome.component.scss'],
})
export class CustomerWelcomeComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
