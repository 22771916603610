import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-car-info',
    templateUrl: './car-info.component.html',
    styleUrls: ['./car-info.component.scss'],
})
export class CarInfoComponent implements OnInit {
    selectedItem;
    modelChecked: boolean;

    modelData = [
        {
            modelName: 'Swift',
            modelImage: 'assets/images/carmodel1.png',
            modelVariant: ['Lxi', 'Vxi', 'Zxi', 'Zdi+'],
        },
        {
            modelName: 'Swift Dezire',
            modelImage: 'assets/images/carmodel2.png',
            modelVariant: [
                { v: 'Lxi' },
                { v: 'Vxi' },
                { v: 'Zxi' },
                { v: 'Zdi+' },
            ],
        },
        {
            modelName: 'Brezza',
            modelImage: 'assets/images/carmodel3.png',
            modelVariant: [
                { v: 'Lxi' },
                { v: 'Vxi' },
                { v: 'Zxi' },
                { v: 'Zdi+' },
            ],
        },
        {
            modelName: 'Swift',
            modelImage: 'assets/images/carmodel1.png',
            modelVariant: [
                { v: 'Lxi' },
                { v: 'Vxi' },
                { v: 'Zxi' },
                { v: 'Zdi+' },
            ],
        },
        {
            modelName: 'Swift Dezire',
            modelImage: 'assets/images/carmodel2.png',
            modelVariant: [
                { v: 'Lxi' },
                { v: 'Vxi' },
                { v: 'Zxi' },
                { v: 'Zdi+' },
            ],
        },
        {
            modelName: 'Brezza',
            modelImage: 'assets/images/carmodel3.png',
            modelVariant: [
                { v: 'Lxi' },
                { v: 'Vxi' },
                { v: 'Zxi' },
                { v: 'Zdi+' },
            ],
        },
        {
            modelName: 'Swift',
            modelImage: 'assets/images/carmodel1.png',
            modelVariant: [
                { v: 'Lxi' },
                { v: 'Vxi' },
                { v: 'Zxi' },
                { v: 'Zdi+' },
            ],
        },
        {
            modelName: 'Swift Dezire',
            modelImage: 'assets/images/carmodel2.png',
            modelVariant: [
                { v: 'Lxi' },
                { v: 'Vxi' },
                { v: 'Zxi' },
                { v: 'Zdi+' },
            ],
        },
        {
            modelName: 'Brezza',
            modelImage: 'assets/images/carmodel3.png',
            modelVariant: [
                { v: 'Lxi' },
                { v: 'Vxi' },
                { v: 'Zxi' },
                { v: 'Zdi+' },
            ],
        },
    ];

    constructor() {}

    ngOnInit() {}

    selectModel(newValue) {
        this.selectedItem = newValue;
        this.modelChecked = true;
    }

    isActive(newValue) {
        return this.selectedItem === newValue;
    }
}
