import { Component, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
    selector: 'app-car-compare',
    templateUrl: './car-compare.component.html',
    styleUrls: ['./car-compare.component.scss'],
})
export class CarCompareComponent implements OnInit {
    @Output() valueChange = new EventEmitter();

    carData: any = [
        {
            overview: 'Overview',
            models: [
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'Swift LXi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'Dzire LDi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'Brezza Vxi',
                    maxpower: '82@6000',
                    fullwheel: false,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'SPresso Lxi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: false,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
            ],
        },
        {
            overview: 'Overview 1',
            models: [
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'Swift LXi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: false,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'Dzire LDi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'Brezza Vxi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'SPresso Lxi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
            ],
        },
        {
            overview: 'Overview 2',
            models: [
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'Swift LXi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'Dzire LDi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'Brezza Vxi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
                {
                    carid: 'AXiCqCEYZPsTbFxueA6C',
                    carname: 'SPresso Lxi',
                    maxpower: '82@6000',
                    fullwheel: true,
                    overallength: 3840,
                    overallwidth: 1735,
                    overallheight: 1530,
                    enginecapacity: 1197,
                    bodycolored: true,
                    price: '4 60 000',
                    carimage:
                        'https://s3.amazonaws.com/mtvdealerappus/631339/60506a7d9b76586050065d89-2021-03-30-14-49-02/image1.jpg',
                },
            ],
        },
    ];

    constructor() {}

    ngOnInit() {
        this.valueChanged();
    }

    valueChanged() {
        this.valueChange.emit(true);
    }

    toggleAccordian(event, index) {
        const element = event.target;
        element.classList.toggle('active');
        console.log(this.carData[index]);
        if (this.carData[index].isActive) {
            this.carData[index].isActive = false;
        } else {
            this.carData[index].isActive = true;
        }
        const panel = element.parentElement.nextElementSibling;

        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + 'px';
        }
    }
}
