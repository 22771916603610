import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-select-car',
    templateUrl: './select-car.component.html',
    styleUrls: ['./select-car.component.scss'],
})
export class SelectCarComponent implements OnInit {
    carData: any = [
        {
            parentName: 'Exterior',
            childProperties: [
                { propertyName: 'LED Rear Combination Lamp' },
                { propertyName: 'LED High Mounted Stop Lamp' },
                { propertyName: 'Steel Wheels' },
                { propertyName: 'Body Coloured Bumpers' },
                { propertyName: 'Side Turn Indicators on ORVMs' },
                { propertyName: 'Full Wheel Covers' },
                { propertyName: 'Body Coloured ORVMs  ' },
            ],
        },
        {
            parentName: 'Interior',
            childProperties: [
                { propertyName: 'LED Rear Combination Lamp' },
                { propertyName: 'LED High Mounted Stop Lamp' },
                { propertyName: 'Steel Wheels' },
                { propertyName: 'Body Coloured Bumpers' },
                { propertyName: 'Side Turn Indicators on ORVMs' },
                { propertyName: 'Full Wheel Covers' },
                { propertyName: 'Body Coloured ORVMs  ' },
            ],
        },
        {
            parentName: 'Audio & Entertainment',
            childProperties: [
                { propertyName: 'LED Rear Combination Lamp' },
                { propertyName: 'LED High Mounted Stop Lamp' },
                { propertyName: 'Steel Wheels' },
                { propertyName: 'Body Coloured Bumpers' },
                { propertyName: 'Side Turn Indicators on ORVMs' },
                { propertyName: 'Full Wheel Covers' },
                { propertyName: 'Body Coloured ORVMs  ' },
            ],
        },
        {
            parentName: 'Comfort & Convenience',
            childProperties: [
                { propertyName: 'LED Rear Combination Lamp' },
                { propertyName: 'LED High Mounted Stop Lamp' },
                { propertyName: 'Steel Wheels' },
                { propertyName: 'Body Coloured Bumpers' },
                { propertyName: 'Side Turn Indicators on ORVMs' },
                { propertyName: 'Full Wheel Covers' },
                { propertyName: 'Body Coloured ORVMs  ' },
            ],
        },
    ];

    constructor() {}

    ngOnInit() {}

    toggleAccordian(event, index) {
        const element = event.target;
        element.classList.toggle('active');
        if (this.carData[index].isActive) {
            this.carData[index].isActive = false;
        } else {
            this.carData[index].isActive = true;
        }
        const panel = element.parentElement.nextElementSibling;
        console.log(panel);
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + 'px';
        }
    }
}
