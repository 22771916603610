import { Component, ElementRef, OnInit } from '@angular/core';
import { DataService } from 'src/app/_service/data-service.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    host: {
        '(document:click)': 'onClick($event)',
    },
})
export class HeaderComponent implements OnInit {
    customer_id: any;
    meeting_id: any;
    data: any;
    subscription: Subscription;
    showInfo: boolean = false;
    routeUrl: string = 'customer';

    constructor(
        private dataService: DataService,
        private _eref: ElementRef,
        private route: Router
    ) {
        this.subscription = this.dataService.getData().subscribe((x) => {
            this.data = x;
        });
        this.routeUrl = this.route.url.split('/')[1];
    }

    ngOnInit() {}

    onClick(event) {
        if (!this._eref.nativeElement.contains(event.target))
            // or some similar check
            this.showInfo = false;
    }

    public customerDetails() {
        let data = {
            customer_id: this.customer_id,
        };
        this.dataService.post(`customer/details`, data).subscribe((res) => {
            this.dataService.stopLoader();
            if (res['responseCode'] == 200) {
                this.data = res['data'];
                this.dataService.setCookies(
                    'customer',
                    JSON.stringify(res['data'])
                );
            } else {
                this.dataService.showError(res['responseMessage']);
            }
        });
    }

    showLoggedInfo() {
        this.showInfo = !this.showInfo;
    }

    refresh() {
        window.location.reload();
    }
}
