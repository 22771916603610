import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-customer-preferences',
    templateUrl: './customer-preferences.component.html',
    styleUrls: ['./customer-preferences.component.scss'],
})
export class CustomerPreferencesComponent implements OnInit {
    prefData: any = [
        {
            preferences: 'Features',
        },
        {
            preferences: 'Images',
        },
        {
            preferences: 'Car Price',
        },
        {
            preferences: 'Videos',
        },
        {
            preferences: 'Variant Comparision',
        },
        {
            preferences: 'Car Comparision',
        },
        {
            preferences: 'E-Brochure',
        },
        {
            preferences: 'EMI Calculator',
        },
        {
            preferences: 'Booking',
        },
        {
            preferences: 'Calculate Kiya',
        },
        {
            preferences: 'MGA',
        },
        {
            preferences: 'Configurator',
        },
        {
            preferences: 'Subscribe',
        },
        {
            preferences: 'MSF',
        },
        {
            preferences: 'MSR',
        },
        {
            preferences: 'MI',
        },
    ];

    constructor() {}

    ngOnInit() {}
}
