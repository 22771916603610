import { Component, ElementRef, OnInit } from '@angular/core';
import { DataService } from 'src/app/_service/data-service.service';
import { environment } from 'src/environments/environment';
declare const window: any;
declare const $: any;

@Component({
    selector: 'app-meeting-completed',
    templateUrl: './meeting-completed.component.html',
    styleUrls: ['./meeting-completed.component.scss'],
})
export class MeetingCompletedComponent implements OnInit {
    meeting_id: string = '';
    customer: any;
    constructor(
        private elementRef: ElementRef,
        private dataService: DataService
    ) {
        this.meeting_id = this.dataService.getCookies('meeting_id');
        this.customer = this.dataService.getCookies('customer') || '';
        if (this.customer) {
            this.customer = JSON.parse(this.customer);
        }
    }

    ngOnInit() {
        if (
            this.customer &&
            this.customer.is_primary &&
            this.customer.status_cd == 'CO'
        ) {
            this.dataService.startLoader();
            this.loadScript();
        }
    }

    ngOnDestroy() {
        this.dataService.deleteAllCookies();
        this.removeScript();
    }

    removeScript() {
        const scriptList = document.querySelectorAll(
            "script[type='text/javascript']"
        );
        const convertedNodeList = Array.from(scriptList);
        const testScript = convertedNodeList.find(
            (script) => script.id === 'feedback-form'
        );
        if (testScript) testScript.parentNode.removeChild(testScript);
    }

    loadScript() {
        this.removeScript();
        this.onWindowLoad();
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.id = 'feedback-form';
        s.src = `https://api.getcloudcherry.com/api/JavascriptMicroCampaignLite/6130dbc4e7752353a7139e52`;
        s.async = true;
        document.getElementsByTagName('head')[0].appendChild(s);
    }

    onWindowLoad() {
        let self = this;

        $(window).load(function () {
            var token = environment.feedback_token;

            var questionId1 = '6141b90c965767ab59ea3b13';
            var answer1 = self.customer.dms_meeting_id; //replace {{meeting_id_variable}} with dynamic variable

            var questionId2 = '6183893da717d2254f059a0e';
            var answer2 = self.customer.customer_name; //replace {{Name_id_variable}} with dynamic variable

            var questionId3 = '6183897a393ab2db16ee390c';
            var answer3 = `${self.customer.dealer_cd}-${self.customer.for_cd}`; //replace {{dealer_code_variable}} with dynamic variable

            var questionId4 = '618389afa717d2254f059a6d';
            var answer4 = self.customer.name; //replace {{dse_name_variable}} with dynamic variable

            var questionId5 = '618389eda717d2254f059aa1';
            var answer5 = self.customer.host_email; //replace {{webex_id_variable}} with dynamic variable

            console.log('questionId1============>>>>', questionId1);
            console.log('answer1============>>>>', answer1);
            console.log('questionId2============>>>>', questionId2);
            console.log('answer2============>>>>', answer2);
            console.log('questionId3============>>>>', questionId3);
            console.log('answer3============>>>>', answer3);
            console.log('questionId4============>>>>', questionId4);
            console.log('answer4============>>>>', answer4);
            console.log('questionId5============>>>>', questionId5);
            console.log('answer5============>>>>', answer5);

            // window.CCSDK("prefill", token, questionId1, answer1, questionId2, answer2, questionId3, answer3, questionId4, answer4, questionId5, answer5);
            window.CCSDK('prefill', token, questionId1, answer1);
            window.CCSDK('prefill', token, questionId2, answer2);
            window.CCSDK('prefill', token, questionId3, answer3);
            window.CCSDK('prefill', token, questionId4, answer4);
            window.CCSDK('prefill', token, questionId5, answer5);
        });
        this.dataService.stopLoader();
    }
}
