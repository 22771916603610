import { Injectable, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import io from 'socket.io-client';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    // private socket = io(environment.socketUrl, { transports: ['websocket']});
    private socket = io(environment.socketUrl);

    constructor() {}

    joinRoom(data) {
        this.socket.emit('join', data);
    }

    newUserJoined() {
        let observable = new Observable<{ user: String; message: String }>(
            (observer) => {
                this.socket.on('new user joined', (data) => {
                    observer.next(data);
                });
                return () => {
                    this.socket.disconnect();
                };
            }
        );

        return observable;
    }

    leaveRoom(data) {
        this.socket.emit('leave', data);
    }

    userLeftRoom() {
        let observable = new Observable<{ user: String; message: String }>(
            (observer) => {
                this.socket.on('left room', (data) => {
                    observer.next(data);
                });
                return () => {
                    this.socket.disconnect();
                };
            }
        );

        return observable;
    }

    sendMessage(data) {
        this.socket.emit('message', data);
    }

    newMessageReceived() {
        let observable = new Observable<{ user: String; message: String }>(
            (observer) => {
                this.socket.on('new message', (data) => {
                    observer.next(data);
                });
                return () => {
                    this.socket.disconnect();
                };
            }
        );

        return observable;
    }
}
